<script lang="ts">
	export let color: 'black' | 'white' | 'none' = 'white'
	export let home_url = '/'
</script>

{#if home_url}
	<a
		href={home_url}
		class:text-white={color === 'white'}
		class:text-gray-900={color === 'black'}
		class:hidden={color === 'none'}
	>
		<span class="sr-only">hiral</span>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xml:space="preserve"
			fill-rule="evenodd"
			stroke-linejoin="round"
			stroke-miterlimit="2"
			clip-rule="evenodd"
			viewBox="0 0 400 400"
			class="logo h-[80px] w-auto fill-current"
			><path
				d="M400 3.718A3.72 3.72 0 0 0 396.282 0H3.718A3.72 3.72 0 0 0 0 3.718v392.564A3.72 3.72 0 0 0 3.718 400h392.564a3.72 3.72 0 0 0 3.718-3.718V3.718Zm-20 19.628c0-1.847-1.5-3.346-3.346-3.346H23.346A3.347 3.347 0 0 0 20 23.346v353.308A3.348 3.348 0 0 0 23.346 380h353.308c1.847 0 3.346-1.5 3.346-3.346V23.346Z"
			/><g fill-rule="nonzero"
				><path
					d="M95.73 304h39.167c2.592 0 4.608-2.016 4.608-4.608v-69.408c0-17.568 7.776-26.208 21.6-26.208 14.112 0 21.312 8.64 21.312 26.208v69.408c0 2.592 2.016 4.608 4.608 4.608h38.88c2.592 0 4.896-2.016 4.896-4.608v-81.504c0-36.576-21.888-58.464-50.976-58.464-19.872 0-32.256 9.792-40.32 24.192v-80.928c0-2.592-2.016-4.608-4.608-4.608H95.73c-2.592 0-4.608 2.016-4.608 4.608v196.704c0 2.592 2.016 4.608 4.608 4.608ZM285.233 143.008c14.976 0 26.208-11.52 26.208-26.208 0-14.4-11.232-25.92-26.208-25.92-15.264 0-26.208 11.52-26.208 25.92 0 14.688 10.944 26.208 26.208 26.208Zm-24.48 156.384c0 2.592 2.016 4.608 4.608 4.608h39.744c2.592 0 4.608-2.016 4.608-4.608V167.488c0-2.592-2.016-4.608-4.608-4.608h-39.744c-2.592 0-4.608 2.016-4.608 4.608v131.904Z"
				/></g
			></svg
		>
	</a>
{:else}
	<div
		class:text-white={color === 'white'}
		class:text-gray-900={color === 'black'}
		class:hidden={color === 'none'}
	>
		<span class="sr-only">hiral</span>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xml:space="preserve"
			fill-rule="evenodd"
			stroke-linejoin="round"
			stroke-miterlimit="2"
			clip-rule="evenodd"
			viewBox="0 0 400 400"
			class="logo h-[80px] w-auto fill-current"
			><path
				d="M400 3.718A3.72 3.72 0 0 0 396.282 0H3.718A3.72 3.72 0 0 0 0 3.718v392.564A3.72 3.72 0 0 0 3.718 400h392.564a3.72 3.72 0 0 0 3.718-3.718V3.718Zm-20 19.628c0-1.847-1.5-3.346-3.346-3.346H23.346A3.347 3.347 0 0 0 20 23.346v353.308A3.348 3.348 0 0 0 23.346 380h353.308c1.847 0 3.346-1.5 3.346-3.346V23.346Z"
			/><g fill-rule="nonzero"
				><path
					d="M95.73 304h39.167c2.592 0 4.608-2.016 4.608-4.608v-69.408c0-17.568 7.776-26.208 21.6-26.208 14.112 0 21.312 8.64 21.312 26.208v69.408c0 2.592 2.016 4.608 4.608 4.608h38.88c2.592 0 4.896-2.016 4.896-4.608v-81.504c0-36.576-21.888-58.464-50.976-58.464-19.872 0-32.256 9.792-40.32 24.192v-80.928c0-2.592-2.016-4.608-4.608-4.608H95.73c-2.592 0-4.608 2.016-4.608 4.608v196.704c0 2.592 2.016 4.608 4.608 4.608ZM285.233 143.008c14.976 0 26.208-11.52 26.208-26.208 0-14.4-11.232-25.92-26.208-25.92-15.264 0-26.208 11.52-26.208 25.92 0 14.688 10.944 26.208 26.208 26.208Zm-24.48 156.384c0 2.592 2.016 4.608 4.608 4.608h39.744c2.592 0 4.608-2.016 4.608-4.608V167.488c0-2.592-2.016-4.608-4.608-4.608h-39.744c-2.592 0-4.608 2.016-4.608 4.608v131.904Z"
				/></g
			></svg
		>
	</div>
{/if}
